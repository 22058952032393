import { defineStore } from 'pinia';

type Action = (state?: Snackbar) => void;
type Color = 'default' | 'error' | 'info' | 'success' | 'warn';

export interface Snackbar {
  visible?: boolean;
  message: string;
  type?: Color;
  actionName?: string;
  actionRunner: Action | null;
}

// Use options store for better dev tools integration
export const useSnackbarStore = defineStore('Snackbar', {
  state: () => ({
    visible: false as boolean,
    message: '',
    actionName: 'Sluiten',
    type: 'default' as Color,
    // NOTE: can't be a function, will cause hydration error
    actionRunner: null as Action | null,
  }),

  getters: {
    isVisible: (state) => state.visible,
    getName: (state) => state.actionName,
    getMessage: (state) => state.message,
  },

  actions: {
    setVisibility(visible: boolean) {
      this.visible = visible;
    },

    showSnackbar(snackbar: Snackbar) {
      this.visible = snackbar.visible === undefined ? true : snackbar.visible;
      this.message = snackbar.message;
      if (snackbar.type) this.type = snackbar.type;
      if (snackbar.actionName) this.actionName = snackbar.actionName;
      if (snackbar.actionRunner) this.actionRunner = snackbar.actionRunner;
    },

    action() {
      if (typeof this.actionRunner === 'function') {
        this.actionRunner(this.$state);
      }
      this.resetState();
      this.$state;
    },

    resetState() {
      this.visible = false;

      // Wait for animation to finish
      setTimeout(() => {
        this.message = '';
        this.type = 'default';
        this.actionName = 'Sluiten';
      }, 500);
    },
  },
});
